import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import routes from './config';
import { Styles } from '../styles/styles';

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <BrowserRouter>
        <Switch>
          {routes.map((routeItem) => {
            return (
              <Route
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact}
                component={lazy(
                  () => import(`../pages/${routeItem.component}`),
                )}
              />
            );
          })}
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
