const routes = [
  {
    path: ['/', '/home'],
    exact: true,
    component: 'Home',
  },
  {
    path: '/login',
    exact: true,
    component: 'Auth/LoginPage',
  },
  {
    path: '/register',
    exact: true,
    component: 'Auth/RegisterPage',
  },
  {
    path: '/forgot-password',
    exact: true,
    component: 'Auth/ForgotPage',
  },
  {
    path: '/verify-otp',
    exact: true,
    component: 'Auth/VerifyOTPPage',
  },
  {
    path: '/profile',
    exact: true,
    component: 'User/Profile',
  },
];

export default routes;
