import React from 'react';
import { notification } from 'antd';
import { NotificationInstance } from 'antd/lib/notification/interface';

interface AppContextProps {
  children: React.ReactNode;
}

interface AppContextState {
  notification: NotificationInstance;
}

export const AppContext = React.createContext<AppContextState>({
  notification: notification,
});

export const useAppContext = () => React.useContext(AppContext);

//user Reducer to manage user state

export function AppContextProvider({ children }: AppContextProps) {
  const [notifyApi, contextHolder] = notification.useNotification({
    duration: 3,
  });

  return (
    <AppContext.Provider value={{ notification: notifyApi }}>
      {contextHolder}
      {children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
