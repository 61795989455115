import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import Router from './router';
import i18n from './translation';
import { ConfigProvider } from 'antd';
import AppContextProvider from './layouts/app.context';
import { createRoot } from 'react-dom/client';

const App = () => (
  <BrowserRouter>
    <AppContextProvider>
      <I18nextProvider i18n={i18n}>
        <ConfigProvider>
          <Router />
        </ConfigProvider>
      </I18nextProvider>
    </AppContextProvider>
  </BrowserRouter>
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
